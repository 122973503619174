import React, { useState, useEffect } from "react";
import { Select, Text } from "@components/Primitives";
import { observer } from "mobx-react";
import { Form } from "@components/Form";
import { FormattedMessage as M } from "gatsby-plugin-intl";
import { useStores, ToastStore } from "@stores";
import { sendFaultReportAttachment } from "../../services/api";
import { IFormField } from "@components/Form/FormField";
import { Heading } from "@primitives";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { FileUploader } from "@components/FileUploader";
// @ts-ignore
import AttachmentIcon from "@icons/attachment.svg";
import { MessageAttachments } from "@components/Messages";
import { BlueBox } from "@components/BlueBox";

interface FaultReportFormProps {
  fields: any;
  id: string;
  values?: any;
}
const HardCodeHead = styled(Text)`
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 16px;
color: #07234B;
`
const StyledForm = styled(Form)`
  .input-wrapper {
    &.field_topic {
      margin-bottom: 2rem;
    }
    &.field_phoneNumber {
      margin-top: 1rem;
    }
    &.field_description {
      margin-bottom: 0;
    }
  }
`;

const AttachmentUploader = styled(FileUploader)`
  width: auto;
  margin-right: 1rem;
  #upload-file-attachments {
    border: 2px solid ${(props) => props.theme.colors.textBlue};
  }
`;

const FaultReportForm: React.FC<FaultReportFormProps> = observer(
  ({ fields, id, values }) => {
    const [sendingAttachment, setSendingAttachment] = useState(false);
    const [attachments, setAttachments] = useState<IMessageAttachment[] | []>(
      []
    );
    const [sharedSpace, setSharedSpace] = useState('');
    const [inSharedSpace, setInSharedSpace] = useState(true)
    const [pets, setPets] = useState('');
    const [entry, setEntry] = useState('')

    useEffect(() => {
      if (sharedSpace === 'Asunto') {
        setInSharedSpace(false)
      }
      else {
        setInSharedSpace(true)
      }
      // console.log(inSharedSpace)
    }, [sharedSpace, inSharedSpace])

    const sharedSpaceOptions = [
      { label: 'sharedSelect.inapartment', value: 'inapartment' },
      { label: 'sharedSelect.inshared', value: 'inshared' }
    ];

    const petsOptions = [
      { id: 'nopets', label: 'petsSelect.nopets' },
      { id: 'petsnoentry', label: 'petsSelect.petsnoentry' },
      { id: 'petsentryok', label: 'petsSelect.petsentryok' },
    ];

    const entryOptions = [
      { id: 'tenantavailable', label: 'entrySelect.tenantavailable' },
      { id: 'noresidencevisitrequired', label: 'entrySelect.noresidencevisitrequired' },
      { id: 'calltoarrange', label: 'entrySelect.calltoarrange' },
      { id: 'masterkey', label: 'entrySelect.masterkey' },
      { id: 'masterkeychildrenpresent', label: 'entrySelect.masterkeychildrenpresent' },
    ];

    let combinedData = {};
    const {
      agreementStore: { selectedAgreement, selectedAgreementId },
      currentUserStore: { currentUser },
      faultReportStore: { createFaultReport },
    }: {
      agreementStore: IAgreementStore;
      currentUserStore: ICurrentUserStore;
      faultReportStore: IFaultReportStore;
    } = useStores();

    if (selectedAgreement && currentUser) {
      combinedData = {
        ...selectedAgreement.dwelling,
        ...currentUser,
        ...values,
      };
    }

    const onSubmit = async (
      _diff: any,
      callback: Function,
      formData: any,
    ) => {
      const { description, title, phone_number, } =
        formData;
      if (selectedAgreementId) {
        try {
          createFaultReport(
            selectedAgreementId,
            {
              pets: pets,
              entry: entry,
              description,
              title,
              phone_number,
              common_areas: inSharedSpace,
              files: attachments,
            },
            callback
          );

          navigate("/me/fault-reports");
        } catch (error) {
          console.error(error);
        }
      }
    };

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.currentTarget.files) return;
      const file = e.currentTarget.files[0];
      setSendingAttachment(true);
      try {
        const results = await sendFaultReportAttachment(
          file,
          selectedAgreementId!
        );
        const newAttachments = [...[], ...attachments];
        newAttachments.push({
          download_id: results.data.download_id,
          name: file.name,
          filetype: file.type,
        });
        setSendingAttachment(false);
        setAttachments(newAttachments);
      } catch {
        ToastStore.showError("errors.sendAttachment", true);
        setSendingAttachment(false);
      }
    };

    const onAttachmentRemove = (name: File["name"]) => {
      const newAttachments = attachments.filter((a) => a.name !== name);
      setAttachments(newAttachments);
    };

    const getTranslation = (id: string) => (
      <M id={`fields.label.faultReport.${id}`} />
    );

    const renderFormElement = (field: IFormField) => {
      switch (field.id) {
        case "shared_space":
          return (
            <div>
            <HardCodeHead mb="0.75">
              {getTranslation('shared_space')}
            </HardCodeHead>
            <Select 
              id={'shared_space'}
              options={sharedSpaceOptions}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setSharedSpace(e.target.value);
              }}
              value={sharedSpace}
            />
            </div>
          )
        case "descriptionText":
          return (
            <Text mt="2" mb="2">
              {getTranslation("description")}
            </Text>
          );
        case "informerHeading":
          return (
            <Heading mt="2" mb="2" level="3">
              {getTranslation("informer")}
            </Heading>
          );
        case "dwellingAccessHeading":
          if (sharedSpace === 'Asunto') {
          return (
            <Heading mt="2" mb="2" level="3">
              {getTranslation("dwellingAccessHeading")}
            </Heading>
          );
          }
          else return null
        case "entry": 
          if (sharedSpace === 'Asunto') {
            return (
              <div>
              <HardCodeHead mb="0.75">
              {getTranslation('dwellingAccess')}
              </HardCodeHead>
              <Select 
              id={'entry'}
              options={entryOptions}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setEntry(e.target.value);
              }}
              value={entry}
              />
              </div>
            )
          }
          else if (sharedSpace === 'Yleinen tila') (
            setEntry('tenantavailable')
          )
        else return null;
        case "pets": 
        if (sharedSpace === 'Asunto') {
          return (
            <div>
            <HardCodeHead mb="0.75">
            {getTranslation('dwellingPets')}
            </HardCodeHead>
            <Select 
            id={'pets'}
            options={petsOptions}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setPets(e.target.value);
            }}
            value={pets}
            />
            </div>
          )
        }
        else if (sharedSpace === 'Yleinen tila') {
          setPets('nopets')
        }
        case "attachments":
          return (
            <div>
              {!!attachments.length && (
                <MessageAttachments
                  attachments={attachments as any}
                  onAttachmentRemove={onAttachmentRemove}
                />
              )}
              <AttachmentUploader
                color="textBlue"
                background="white"
                onChange={onFileChange}
                disabled={sendingAttachment}
                id="attachments"
              >
                <AttachmentIcon />
                <Text ml="0.5">
                  <M id="pages.messages.addAttachment" />
                </Text>
              </AttachmentUploader>
            </div>
          );

        default:
          return `Cannot render ${field.id}`;
      }
    };

    return (
      <>
        <StyledForm
          onSubmit={onSubmit}
          id={id}
          values={combinedData}
          fields={fields}
          columnGap={2}
          customRenderer={renderFormElement}
          styles={["labelOnTop"]}
        />
      </>
    );
  }
);

export { FaultReportForm };
