/* eslint-disable react/no-danger */
import React, { FC, useEffect } from 'react';
import { ContentPageLayout } from '@components/Layout';
import { Heading, Box } from '@primitives';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { observer } from 'mobx-react';
import { RESERVE_PARKING_LOT } from '../../constants/other-form-fields';
import { FaultReportForm } from '@components/FaultReportForm';
import { useStores } from '../../stores/index';
import { IAgreementStore } from '../../types/Agreement';
import { ParkingSpaceTable } from '@components/Table';
import { graphql } from 'gatsby';
import { TextSection } from '@components/TextSection';
import stripTags from 'striptags';

interface IReserveParkingLotFormPage {
  data: any;
}

const ReserveParkingLotFormPage: FC<IReserveParkingLotFormPage> = observer(({ data: { markdownRemark } }) => {
  const {
    agreementStore: { selectedAgreementId, getParkingLots, parkingLots: allParkingLots },
  }: {
    agreementStore: IAgreementStore,
  } = useStores();

  useEffect(() => {
    if (selectedAgreementId && !allParkingLots.find((lotObj) => lotObj.agreement_id === selectedAgreementId)) {
      getParkingLots(selectedAgreementId);
    }
  }, [getParkingLots, allParkingLots, selectedAgreementId]);

  const parkingLots = allParkingLots.find((lotObj) => lotObj.agreement_id === selectedAgreementId)?.parkingLots;

  return (
    <ContentPageLayout>
      <TextSection title={markdownRemark.frontmatter.title} text={stripTags(markdownRemark.html)} />
      {!!parkingLots?.vacant.length && (
        <Box mb="3">
          <Heading level="3" mt="1" mb="1"><M id="pages.myInformation.numberFreeSpaces" /></Heading>
          <ParkingSpaceTable id="freeSpaces" data={parkingLots?.vacant} />
        </Box>
      )}

      {!!parkingLots?.becomingVacant.length && (
        <Box mb="3">
          <Heading level="3" mt="1" mb="1"><M id="pages.myInformation.numberUpcomingFreeSpaces" /></Heading>
          <ParkingSpaceTable id="upComingFreeSpaces" data={parkingLots?.becomingVacant} />
        </Box>
      )}
      <FaultReportForm id="reserve-parking-lot" fields={RESERVE_PARKING_LOT} values={{ title: 'Peru/varaa autopaikka' }} />
    </ContentPageLayout>
  );
});

export const pageQuery = graphql`
query ReserveParkingLotFormQuery {
  markdownRemark(frontmatter: {path: {eq: "/reserve-parking-lot/"}}) {
    frontmatter {
      path
      title
    }
    html
  }
}`;

export default ReserveParkingLotFormPage;
